<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{$tc('administrator', 2) | toCapitalize}}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button v-if="type === 'SUPER' || type === 'FULL'" variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="administrators" :fields="columns" :filter="filter">
      <template slot="type" slot-scope="administrator">
        {{ administrator.item.type === 'A' ? 'ADMINISTRATOR' : 'FULLADMINISTRATOR' }}
      </template>
      <template v-if="type === 'SUPER' || type === 'FULL'" slot="actions" slot-scope="administrator">
        <div class="w-100 text-center">
          <b-button variant="danger" @click="openModalDestroy(administrator.item)"><i class="fa fa-trash"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-form @submit.prevent="sendInvitation">
      <b-modal ref="modalAdd" :title="$t('createAdmins')">
        <div class="form-group">
          <label for="user">{{$t('enterPlayerNick')}}</label>
          <multiselect v-model="userSelected" id="ajax" label="nick" track-by="nick" open-direction="bottom" :options="users" :multiple="false" :searchable="true" :loading="loading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="10" :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind" :placeholder="$t('selectOption')" deselectLabel="" selectLabel="" selectedLabel="" name="user_id" v-validate="'required'" :data-vv-as="$t('user')" :state="validateState('user_id')"></multiselect>
        </div>
        <div slot="modal-footer">
          <b-button @click="$refs.modalAdd.hide()">{{$t('cancel')}}</b-button>
          <b-button variant="primary" type="submit" class="ml-3">{{$t('sendInvitation')}}</b-button>
        </div>
      </b-modal>
    </b-form>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ administrator.nick }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      filter: '',
      administrators: [],
      administrator: {},
      users: [],
      userSelected: {},
      columns: [
        { key: 'nick', label: 'Nick', sortable: true },
        { key: 'type', label: 'Nick', sortable: true },
        { key: 'actions', label: '' }
      ],
      type: null
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'tournament'
    ])
  },
  mounted() {
    if (!this.tournament.name) {
      this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
      return
    }
    document.title = `${this.tournament.name} - ${this.$tc('administrator', 2)}`
    this.fetchData()
  },
  methods: {
    limitText (count) {
      return `and ${count} other countries`
    },
    asyncFind (query) {
      if (query && query.length > 2) {
        const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/administrators/find`
        this.$axios.get(path, { params: { nick: query } }).then((response) => {
          this.users = response.data.data
        })
      }
    },
    create () {
      this.$refs.modalAdd.show()
      this.users = []
      this.userSelected = {}
    },
    openModalDestroy (administrator) {
      this.administrator = administrator
      this.$refs.modalDelete.show()
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/administrators/${this.administrator.id}/delete`
      this.$axios.delete(path).then(() => {
        this.administrators = this.administrators.filter(admin => {
          return admin.id !== this.administrator.id
        })
        this.administrator = {}
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDelete.hide()
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/administrators/all`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.administrators = data.data
        this.type = data.type
      })
    },
    sendInvitation () {
      this.$validator.validate().then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/administrators/send-invitation`
          const payload = {
            user_id: this.userSelected.id
          }
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            this.$refs.modalAdd.hide()
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
